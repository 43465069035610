import { useEffect, useRef } from 'react'

export function useInterval(callback: Function, delay: number = 500) {
  const savedCallback = useRef<Function>(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    let id: any
    // delay initial tick
    setTimeout(() => {
      id = setInterval(tick, delay)
    }, delay)
    return () => {
      if (id) {
        clearInterval(id)
      }
    }
  }, [delay])
}
