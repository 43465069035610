import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import storage from 'store2'
import { checkSession } from '~/apis/auth'
import { useInterval } from '~/hooks/useInterval'

enum AuthActionTypes {
  AUTHENTICATE_USER = 'AUTHENTICATE_USER',
  SET_USER_DATA = 'SET_USER_DATA',
  RESET_AUTH = 'RESET_AUTH',
}

export const AUTH_TOKEN = 'drop_pulse_tkn'
const POLL_AUTH0_SESSION_INTERVAL = 900000

const initialState = {
  isAuthenticated: !!storage.has(AUTH_TOKEN),
  authToken: storage.has(AUTH_TOKEN) ? storage.get(AUTH_TOKEN) : '',
  user: undefined,
}

const reducer = (
  state: typeof initialState,
  action: { type: AuthActionTypes; payload?: any }
) => {
  switch (action.type) {
    case AuthActionTypes.AUTHENTICATE_USER:
      storage.set(AUTH_TOKEN, action.payload)
      return { ...state, isAuthenticated: true, authToken: action.payload }
    case AuthActionTypes.SET_USER_DATA:
      return { ...state, user: action.payload }
    case AuthActionTypes.RESET_AUTH:
      storage.clearAll()
      return {
        ...state,
        isAuthenticated: false,
        authToken: undefined,
        user: undefined,
      }
    default:
      return state
  }
}

const AuthContext = createContext(initialState)

const AuthProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const authenticateUser = useCallback((authToken: string) => {
    dispatch({ type: AuthActionTypes.AUTHENTICATE_USER, payload: authToken })
  }, [])

  const setUserData = useCallback((data: any) => {
    if ((window as any).heap)
      (window as any).heap.addUserProperties({ email: data.name })
    dispatch({ type: AuthActionTypes.SET_USER_DATA, payload: data })
  }, [])

  const resetAuth = useCallback(() => {
    dispatch({ type: AuthActionTypes.RESET_AUTH })
  }, [])

  const verifySession = useCallback(async () => {
    try {
      const { accessToken, idTokenPayload } = await checkSession()
      authenticateUser(accessToken)
      setUserData(idTokenPayload)
    } catch (error) {
      resetAuth()
    }
  }, [])

  useInterval(async () => {
    await checkSession()
  }, POLL_AUTH0_SESSION_INTERVAL)

  useEffect(() => {
    verifySession()
  }, [])

  const contextValue: any = useMemo(
    () => ({ ...state, authenticateUser, setUserData, resetAuth }),
    [state, authenticateUser, setUserData, resetAuth]
  )

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

type AuthContextType = typeof initialState & {
  authenticateUser(token: string): void
  setUserData(data: any): void
  resetAuth(): void
}

const useAuthContext = (): AuthContextType =>
  useContext(AuthContext) as AuthContextType

export { AuthContext, AuthProvider, useAuthContext }
