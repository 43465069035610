import { useFeature } from '@growthbook/growthbook-react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { useAuthContext } from '~/features/auth/auth-context'
import { PageLayout, DashboardLayout } from '~/layouts'

import {
  ViewCampaignPerformance,
  CreateOffer,
  LoginPage,
  EmailSentPage,
  AuthorizePage,
  NotFound,
  OfferDashboard,
  AffiliateOfferCreation,
  ViewPerformanceSummary,
} from '~/pages'
import { PrivateRoute } from './PrivateRoute'

export const AppRouter = () => {
  const { isAuthenticated } = useAuthContext()
  const hasOfferCreation = useFeature('offer-creation').on

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route
        index
        element={
          isAuthenticated ? <Navigate to="partner-portal" /> : <LoginPage />
        }
      />
      <Route
        path="email-sent"
        element={
          isAuthenticated ? <Navigate to="partner-portal" /> : <EmailSentPage />
        }
      />
      <Route path="authorize" element={<AuthorizePage />} />
      <Route
        path="partner-portal"
        element={<PrivateRoute component={PageLayout} />}
      >
        <Route
          index
          element={
            <DashboardLayout>
              <OfferDashboard />
            </DashboardLayout>
          }
        />
        {hasOfferCreation ? (
          <>
            <Route
              path="affiliate-offer"
              element={<AffiliateOfferCreation />}
            />
            <Route path="offer-form" element={<CreateOffer />} />
          </>
        ) : null}
        )
        <Route path="performance">
          <Route path=":campaignId" element={<ViewCampaignPerformance />} />
        </Route>
        <Route path="performance-summary">
          <Route path=":brandId" element={<ViewPerformanceSummary />} />
        </Route>
      </Route>
    </Routes>
  )
}
