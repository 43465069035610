import * as yup from 'yup'
import {
  CLOFieldNames,
  fields,
  integratedFieldNames,
  nonIntegratedFieldNames,
} from './constants'
import { Field, Rule } from './types'

export const CLOFields = fields.filter((field: Field) =>
  CLOFieldNames.includes(field.name)
)
export const integratedFields = fields.filter((field: Field) =>
  integratedFieldNames.includes(field.name)
)
export const nonIntegratedFields = fields.filter((field: Field) =>
  nonIntegratedFieldNames.includes(field.name)
)

export function reduceValidationsToSchema(fields: Field[]) {
  return fields.reduce((schema, field) => {
    const {
      name,
      validations: { type, rules = [] } = { type: '', rules: [] },
    } = field
    if (!type) return schema
    const fieldType = yup[type] as Function
    let fieldRules = fieldType()
    if (!rules || !rules.length) return { ...schema, [name]: fieldRules }
    rules.forEach((rule: Rule) => {
      const params = [rule.value, rule.defaultMessage].filter(Boolean)
      fieldRules = fieldRules[rule.type](...params)
    })
    return { ...schema, [name]: fieldRules }
  }, {})
}
