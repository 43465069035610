import { ValidationErrorMessage } from '~/components/ValidationErrorMessage'
import { Label } from '~/components/Label'
import styles from './textInput.module.scss'

type Props = {
  label: string
  value: string
  onChange: (event: { target: { value: string } }) => void
  validationError?: string
  placeholder?: string
}

export const TextInput = ({
  onChange,
  value,
  label,
  validationError,
  placeholder,
}: Props) => {
  return (
    <div>
      <Label htmlFor={`${label}-input`} label={label} />
      <input
        className={styles.input}
        value={value}
        onChange={onChange}
        id={`${label}-input`}
        placeholder={placeholder}
      />
      {!!validationError && (
        <ValidationErrorMessage message={validationError} />
      )}
    </div>
  )
}

TextInput.defaultProps = { validationError: '', placeholder: '' }
