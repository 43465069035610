import React from 'react'
import cn from 'classnames'

import styles from './stepContentPanel.module.scss'

type StepContentPanelProps = {
  className?: string
  children: React.ReactNode
}

export const StepContentPanel = ({
  children,
  className = '',
}: StepContentPanelProps) => {
  const classes = cn(styles.panel, className)

  return <div className={classes}>{children}</div>
}
