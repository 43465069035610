import styles from './formHeader.module.scss'
import { SaveButton } from './SaveButton'

interface FormHeaderProps {
  title?: string
  className?: string
}

export const FormHeader = ({ title = '', className = '' }: FormHeaderProps) => (
  <div className={`${styles['header-container']} ${className}`}>
    <h2 className={styles['header-title']}>{title}</h2>
    <SaveButton />
  </div>
)
