import { FC, RefObject, useEffect, useRef } from 'react'

type MenuDropDownProps = {
  isOpen: boolean
  onClickOutside: () => void
  ignoreElement?: RefObject<HTMLDivElement>
}

export const MenuDropDown: FC<MenuDropDownProps> = ({
  children,
  isOpen,
  onClickOutside,
  ignoreElement,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !ignoreElement?.current?.contains(event.target as Node)
      ) {
        if (onClickOutside) onClickOutside()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [ignoreElement, onClickOutside])

  if (!isOpen) return null
  return (
    <div
      ref={ref}
      className="bg-white shadow-2xl absolute flex flex-col top-full right-0 rounded-lg z-10"
    >
      {children}
    </div>
  )
}
