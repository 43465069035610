import cn from 'classnames'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'

import { useStepper } from '~/hooks'

import styles from './stepNav.module.scss'

export const StepNav = () => {
  const { goBack, goNext, isFirstStep, isLastStep } = useStepper()

  const backClasses = cn(
    { [styles['btn-disabled']]: isFirstStep },
    styles['btn-nav'],
    'btn btn-primary'
  )

  const nextClasses = cn(
    { [styles['btn-disabled']]: isLastStep },
    styles['btn-nav'],
    'btn btn-primary'
  )

  return (
    <div className={styles['step-nav']}>
      <button className={backClasses} type="button" onClick={goBack}>
        <RiArrowLeftSLine />
        Back
      </button>
      <button className={nextClasses} type="button" onClick={goNext}>
        Next
        <RiArrowRightSLine />
      </button>
    </div>
  )
}
