import { Reducer } from 'redux'
import { OffersState, OffersActionTypes } from './types'

const INITIAL_STATE = {
  offers: [],
  error: null,
  loading: false,
}

export const reducer: Reducer<OffersState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case OffersActionTypes.CREATE_OFFER_REQUEST:
      return { ...state, loading: true }
    case OffersActionTypes.CREATE_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        offers: [...state.offers, action.payload.campaign],
      }
    case OffersActionTypes.CREATE_OFFER_FAILURE:
      return { ...state, loading: false, error: action.payload.campaign }
    default:
      return state
  }
}
