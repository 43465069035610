import { FC } from 'react'

const typeToColor = {
  error: 'text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800',
  success:
    'text-emerald-700 bg-emerald-100 rounded-lg dark:bg-emerald-200 dark:text-emerald-800',
  info: 'text-sky-700 bg-sky-100 rounded-lg dark:bg-sky-200 dark:text-sky-800',
}

interface Props {
  type?: 'error' | 'success' | 'info'
  icon?: React.ReactNode
}

export const Alert: FC<Props> = ({ children, type = 'info', icon }) => {
  const color = typeToColor[type]

  return (
    <div
      className={`flex p-4 mb-4 gap-2 justify-start text-sm rounded-lg ${color}`}
      role="alert"
    >
      {!!icon && <div>{icon}</div>}
      <div>{children}</div>
    </div>
  )
}

Alert.defaultProps = {
  type: 'info',
}
