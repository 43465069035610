import { Link } from 'react-router-dom'
import styles from './summaryCard.module.scss'
import { useSummaryDataForBrand } from '~/hooks/useSummaryDataForBrand'

export const SummaryCard = ({ brandId }: { brandId: string }) => {
  const { data } = useSummaryDataForBrand(brandId)

  const name = data?.name

  return (
    <div className={styles['card-list']}>
      <div className={styles['top-section']}>
        <h2 className={styles['offer-name']}>{name}</h2>
        <div className={styles['performance-box']}>
          <Link
            to={`performance-summary/${brandId}`}
            className={styles['performance-btn']}
          >
            View Performance
          </Link>
        </div>
      </div>
      <div className={styles['bottom-section']} />
    </div>
  )
}
