import styles from './card.module.scss'

type Props = { image: string; title: string }

export const Image = ({ title, image }: Props) => {
  return (
    <div className={styles['image-container']}>
      <img className={styles.image} src={image} alt={title} />
    </div>
  )
}
