import { applyMiddleware, createStore, Store } from 'redux'
import createSagaMiddleware from '@redux-saga/core'

import rootReducer from './ducks/rootReducer'
import rootSaga from './ducks/rootSaga'

export type ApplicationState = ReturnType<typeof rootReducer>

const sagaMiddleware = createSagaMiddleware()

const store: Store<ApplicationState> = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
)

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState> // A global type to access reducers types
export type AppDispatch = typeof store.dispatch // Type to access dispatch

export default store
