import { GrowthBook } from '@growthbook/growthbook-react'
import axios from 'axios'
import { useEffect } from 'react'

const FEATURES_ENDPOINT =
  'https://cdn.growthbook.io/api/features/key_prod_7c7482cffacec1d4'

const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    // eslint-disable-next-line no-console
    console.log({
      experimentId: experiment.key,
      variationId: result.variationId,
    })
  },
})

export const useGrowthBook = () => {
  useEffect(() => {
    const abortController = new AbortController()
    const { signal } = abortController
    axios.get(FEATURES_ENDPOINT, { signal }).then(response => {
      if (abortController.signal.aborted) return
      growthbook.setFeatures(response.data.features)
    })
  }, [])

  return growthbook
}
