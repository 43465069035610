import cn from 'classnames'
import { useContext, useEffect } from 'react'
import { StepperContext } from '../Stepper'

import styles from './step.module.scss'

export type StepProps = {
  icon: any
  title: string
  className?: string
  htmlFor: number | string | null
}

export const Step = ({ title, htmlFor, icon, className = '' }: StepProps) => {
  const { selectedStep, setSteps, setSelectedStep } = useContext(StepperContext)
  const active = selectedStep === htmlFor
  const contClasses = cn(
    {
      'bg-drop-purple-50': active,
    },
    styles.container,
    className
  )

  const idClasses = cn(
    {
      [styles['id-active']]: active,
    },
    styles['base-id']
  )
  const titleClasses = cn(
    { 'text-drop-purple-500': active },
    'text-sm font-semibold'
  )

  useEffect(() => {
    if (setSteps) setSteps(steps => [...steps, htmlFor])
    if (setSelectedStep)
      setSelectedStep(selectedStep => {
        if (selectedStep === null) return htmlFor
        return selectedStep
      })
  }, [htmlFor, setSelectedStep, setSteps])

  return (
    <div className={contClasses} data-value={htmlFor}>
      <div className={idClasses}>{icon}</div>
      <p className={titleClasses}>{title}</p>
    </div>
  )
}

Step.displayName = 'Step'
