import { useCallback, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { HiUser } from 'react-icons/hi'
import { logout } from '~/apis/auth'
import { useAuthContext } from '~/features/auth/auth-context'
import { ReactComponent as DropLogo } from '~/assets/drop.svg'

import styles from './navbar.module.scss'
import { MenuDropDown } from '~/features/ui'
import { useFeature } from '@growthbook/growthbook-react'

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const { resetAuth } = useAuthContext()
  const hasOfferCreation = useFeature('offer-creation').on
  const hasProfile = useFeature('user-profile').on

  const handleLogout = useCallback(() => {
    logout()
    resetAuth()
  }, [resetAuth])

  const handleMenuClick = useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  }, [isMenuOpen])

  const handleOutSideMenuClick = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  return (
    <nav className={styles.navbar}>
      <div className="mb-2 sm:mb-0">
        <Link to="" className={styles['menu-logo']}>
          <div className="flex justify-start items-center gap-x-4">
            <div className="text-drop-green-500 w-24 h-12">
              <DropLogo />
            </div>
            <div className="text-black text-base font-semibold border-gray-500 border-l px-4">
              Drop Pulse
            </div>
          </div>
        </Link>
      </div>
      <div className={styles.menu}>
        {hasOfferCreation ? (
          <Link to="offer-form" className={styles['menu-item']}>
            Offers
          </Link>
        ) : null}
        <Link to="/partner-portal" className={styles['menu-item']}>
          Performance
        </Link>
        <div
          className={styles['user-menu']}
          onClick={handleMenuClick}
          onKeyDown={handleMenuClick}
          tabIndex={0}
          role="button"
          ref={menuRef}
        >
          <HiUser />
          <MenuDropDown
            onClickOutside={handleOutSideMenuClick}
            isOpen={isMenuOpen}
            ignoreElement={menuRef}
          >
            {hasProfile && (
              <button type="button" className={styles['drop-down-item']}>
                Profile
              </button>
            )}
            <button
              type="button"
              className={styles['drop-down-item']}
              onClick={handleLogout}
            >
              Logout
            </button>
          </MenuDropDown>
        </div>
      </div>
    </nav>
  )
}
