import { all, takeLatest } from 'redux-saga/effects'
import { sagas as offersSagas } from './offers'
import { OffersActionTypes } from './offers/types'

export default function* rootSaga(): any {
  return yield all([
    takeLatest(
      OffersActionTypes.CREATE_OFFER_REQUEST,
      offersSagas.createOffers
    ),
  ])
}
