import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import styles from './offerDashboard.module.scss'
import { TabPanels, Tabs, TabPanel, TabList, Tab } from '~/components/Tabs'
import { EmptyList } from './EmptyList'
import { PerformanceListCard } from './OfferListCard'
import { SummaryCard } from './SummaryCard'
import PulseLoader from 'react-spinners/PulseLoader'
import { useFeature } from '@growthbook/growthbook-react'
import { useQuery } from '@tanstack/react-query'
import { Campaign, QUERY_CONFIG } from '~/apis/queries'

enum OptionTabs {
  launched = 'launched',
  pending = 'pending',
  archive = 'archive',
}

const OptionsTabsValues = Object.values(OptionTabs)

export const OfferDashboard = () => {
  const campaignsQuery = useQuery(QUERY_CONFIG.QUERIES.campaigns)

  const campaigns = campaignsQuery.data?.data.campaigns || []

  const { error } = campaignsQuery
  const { isLoading } = campaignsQuery
  const [search, setSearch] = useSearchParams()

  const hasOfferCreation = useFeature('offer-creation').on

  const selectedTab = useMemo(() => {
    const value = search.get('selected-tab') || OptionTabs.launched
    if (OptionsTabsValues.includes(value as OptionTabs)) {
      return value
    }
    return OptionTabs.launched
  }, [search])

  const launchedCampaigns = campaigns
  const pendingCampaigns: Campaign[] = []

  const summariesQuery = useQuery(QUERY_CONFIG.QUERIES.summaries)
  const summaryData = summariesQuery.data?.data.urls || []

  const displayActiveCampaigns = (campaignList: Campaign[]) => {
    if (error) {
      return <EmptyList />
    }

    if (campaignList && campaignList.length > 0) {
      return campaignList.map((campaign, i) => (
        <PerformanceListCard
          key={`${campaign.name}_${i}`}
          campaign={campaign}
        />
      ))
    }
    return <EmptyList />
  }

  const handleTabChange = useCallback(
    (value: string | number) => {
      setSearch({ 'selected-tab': String(value) })
    },
    [setSearch]
  )

  return (
    <div className="relative">
      <div className={styles['header-bg']}>
        <div className={styles['header-container']}>
          <h2 className={styles['header-title']}>Offers</h2>
          <div className={styles['header-right-section']}>
            <p>Looking to launch a new offer?</p>
            <a
              href="mailto:clientsuccess@joindrop.com"
              className={styles['create-button']}
            >
              Contact us
            </a>
          </div>
        </div>
      </div>
      <div className={styles['page-body']}>
        {isLoading && (
          <div className="absolute left-0 top-24 right-0 h-[calc(100vh-12rem)] flex justify-center items-center">
            <PulseLoader loading={isLoading} color="#195F55" />
          </div>
        )}
        {!isLoading && (
          <Tabs initTab={selectedTab}>
            <div className={styles['tabs-container']}>
              <TabList>
                <Tab value={OptionTabs.launched} onSelect={handleTabChange}>
                  Launched{' '}
                  <span>
                    {(launchedCampaigns && launchedCampaigns.length) || 0}
                  </span>
                </Tab>
                {hasOfferCreation ? (
                  <>
                    <Tab value={OptionTabs.pending} onSelect={handleTabChange}>
                      Pending{' '}
                      <span>
                        {(pendingCampaigns && pendingCampaigns.length) || 0}
                      </span>
                    </Tab>
                    <Tab value={OptionTabs.archive} onSelect={handleTabChange}>
                      Archive <span>0</span>
                    </Tab>
                  </>
                ) : null}
              </TabList>
              <div>
                <p>
                  Sort By: <span className="font-bold">Date Updated</span>
                </p>
              </div>
            </div>
            <TabPanels>
              <TabPanel value={OptionTabs.launched}>
                <div className={styles.offers}>
                  {summaryData.map((summary, i) => (
                    <SummaryCard
                      key={`${summary.name}_${i}`}
                      brandId={summary.id}
                    />
                  ))}
                  {displayActiveCampaigns(launchedCampaigns || [])}
                </div>
              </TabPanel>
              <TabPanel value={OptionTabs.pending}>
                <div className={styles.offers}>
                  {displayActiveCampaigns(pendingCampaigns || [])}
                </div>
              </TabPanel>
              <TabPanel value={OptionTabs.archive}>
                <EmptyList />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </div>
    </div>
  )
}
