import { DropHeading } from '~/features/ui'
import { ReactComponent as NotFoundSVG } from '~/assets/not-found.svg'

export const NotFound = () => {
  return (
    <div className="h-screen flex">
      <div className="hidden md:bg-drop-green-500 md:flex-1 md:flex md:items-center md:h-screen md:justify-center">
        <div className="relative w-96 h-96 rounded-3xl flex items-center justify-center">
          <NotFoundSVG />
        </div>
      </div>
      <div className="bg-white flex-1 flex justify-center items-center">
        <div className="max-w-lg px-12">
          <DropHeading />
          <h1 className="text-xl font-bold text-red-900">
            Oops! Looks Like Something Went Wrong.
          </h1>
        </div>
      </div>
    </div>
  )
}
