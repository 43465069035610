import styles from './viewPerformance.module.scss'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { QUERY_CONFIG } from '~/apis/queries'

const ViewPerformance = () => {
  const { campaignId } = useParams()
  const embedUrlForCampaignQuery = useQuery(
    QUERY_CONFIG.QUERIES.embedUrlForCampaign(campaignId)
  )
  const embedUrl = embedUrlForCampaignQuery.data

  const campaignQuery = useQuery(QUERY_CONFIG.QUERIES.campaignById(campaignId))
  const campaign = campaignQuery.data?.data.campaign

  return (
    <div>
      <div
        className="px-4 py-8"
        style={{
          backgroundColor: 'white',
          border: '1px solid #EAEAEB',
        }}
      >
        <section id="heading" className="container mx-auto px-4">
          <h1>{campaign?.name}</h1>
        </section>
      </div>

      <div className="container mx-auto px-4">
        <section
          id="performance-banner"
          className="relative rounded-lg bg-drop-green-500 p-6 my-4 mt-12 mx-auto text-white"
        >
          <div className="flex flex-col lg:flex-row items-center justify-between gap-4">
            <h2 className="heading-2 text-white">
              Need assistance with your campaign?
            </h2>
            <div className="flex gap-3">
              <a
                href="mailto:clientsuccess@joindrop.com"
                className="btn-black w-48"
              >
                Contact Us
              </a>
            </div>
          </div>
        </section>
        {!!embedUrl && (
          <iframe
            className={styles['looker-dashboard']}
            title="Looker dashboard"
            src={embedUrl}
          />
        )}
      </div>
    </div>
  )
}

export default ViewPerformance
