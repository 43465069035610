import { BrowserRouter } from 'react-router-dom'
import { AppRouter } from '~/routes/AppRouter'
import { AuthProvider } from './features/auth/auth-context'
import { useGrowthBook } from './hooks/useGrowthBook'
import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient()

export const App = () => {
  const growthbook = useGrowthBook()

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <GrowthBookProvider growthbook={growthbook}>
            <AppRouter />
          </GrowthBookProvider>
        </AuthProvider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
