import styles from './singleSelect.module.scss'
import { ValidationErrorMessage } from '~/components/ValidationErrorMessage'
import { Label } from '~/components/Label'

type Props = {
  label: string
  options: { value: string; title: string }[]
  selectedValue: string
  onSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void
  validationError?: string
}

export const SingleSelect = ({
  onSelect,
  options,
  selectedValue,
  label,
  validationError,
}: Props) => {
  return (
    <div>
      <Label htmlFor={`${label}-single-select`} label={label} />
      <select
        className={styles['single-select']}
        value={selectedValue}
        onChange={onSelect}
        id={`${label}-single-select`}
      >
        {options.map(({ value, title }) => {
          return (
            <option key={value} value={value}>
              {title}
            </option>
          )
        })}
      </select>
      {!!validationError && (
        <ValidationErrorMessage message={validationError} />
      )}
    </div>
  )
}

SingleSelect.defaultProps = { validationError: '' }
