import { OfferTypes, OfferStates } from '~/constants'

export interface Offer {
  name: string
  type: OfferTypes
  offerState: OfferStates
  created_at: number
  events_tracked_started_at: number
}

export interface OffersState {
  readonly offers: Offer[]
  readonly error: string | null
  readonly loading: boolean
}

export enum OffersActionTypes {
  CREATE_OFFER_REQUEST = '@offers/CREATE_OFFER_REQUEST',
  CREATE_OFFER_SUCCESS = '@offers/CREATE_OFFER_SUCCESS',
  CREATE_OFFER_FAILURE = '@offers/CREATE_OFFER_FAILURE',
}
