/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'

import { LoginForm } from '~/features/auth/login-form'
import { DropHeading } from '~/features/ui'

export const LoginPage = () => {
  return (
    <div className="h-screen flex">
      <div className="hidden md:bg-drop-green-500 md:flex-1 md:flex md:items-center md:h-screen md:justify-center">
        <div className="relative">
          <div className="relative bg-drop-purple-500 overflow-hidden w-96 h-96 rounded-3xl">
            <img
              src={`${process.env.PUBLIC_URL}/images/auth/login.png`}
              alt="Login"
              className="absolute object-cover"
            />
          </div>
          <div className="rounded-full bg-green-yellow absolute w-24 h-24 -left-12 top-7" />
          <div className="rounded-full bg-green-yellow absolute w-16 h-16 bottom-24 -right-7" />
        </div>
      </div>
      <div className="bg-white flex-1 flex justify-center items-center">
        <div className="max-w-md px-12">
          <DropHeading />
          <h1 className="text-4xl font-bold leading-11 text-gray-800">
            Welcome to Drop Pulse
          </h1>
          <p className="my-6 w-sm text-gray-600">
            Please enter your email to receive a One Time Passcode to log in.
          </p>
          <LoginForm />
          <p className="mt-24 text-gray-600">
            Not registered?{' '}
            <a href="mailto:clientsuccess@joindrop.com" rel="noreferrer">
              Contact Client Success
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
