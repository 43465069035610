import styles from './card.module.scss'
import { Image } from './Image'
import { Content } from './Content'

type Props = {
  isDisabled?: boolean
  children: React.ReactNode
}

export const Card = ({ isDisabled, children }: Props) => (
  <div className={styles['card-container']}>
    {isDisabled && <div className={styles['card-disabled']} />}
    {children}
  </div>
)

Card.Image = Image
Card.Content = Content

Card.defaultProps = {
  isDisabled: false,
}
