import axios from 'axios'
import Swal from 'sweetalert2'
import { logout } from './auth'
import storage from 'store2'
import { AUTH_TOKEN } from '~/features/auth/auth-context'

const API_URL = process.env.REACT_APP_API_URL || '/api'

const api = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
  },
})

api.interceptors.request.use(async config => {
  const token = storage.get(AUTH_TOKEN)
  if (token) {
    config.headers!['access-token'] = token
  }
  config.headers!['Content-Type'] = 'application/json'

  return config
})

api.interceptors.response.use(
  response => response,
  error => {
    switch (error.response.status) {
      case 401:
        Swal.fire({
          title: 'Session Expired',
          text: 'Your session has expired. Would you like to login again?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#195F55',
          cancelButtonColor: '#d1d5db',
          confirmButtonText: 'Yes',
        }).then(result => {
          if (result.isConfirmed) {
            return logout()
          } else {
            return Promise.reject(error)
          }
        })
        break
      case 403:
        Swal.fire({
          icon: 'warning',
          title: 'Unauthorized',
          text:
            'You are not authorized to perform this action. Request access to the app.',
          footer: '<a href="mailto:clientsuccess@joindrop.com">Contact Us</a>',
        }).then(result => {
          if (result.isConfirmed) {
            return logout()
          } else {
            return Promise.reject(error)
          }
        })
        break
      default:
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.error || 'Something went wrong!',
        })
    }
  }
)

export default api
