/* eslint-disable no-console */
/* eslint-disable no-debugger */
import { Auth0ParseHashError } from 'auth0-js'
import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { validateToken, webAuth } from '~/apis/auth'
import { useAuthContext } from '~/features/auth/auth-context'
import { AuthError } from '~/features/auth/auth-error'
import { Loader } from '~/features/ui'

export const AuthorizePage = () => {
  const [authError, setAuthError] = useState<Auth0ParseHashError | null>()
  const {
    authenticateUser,
    setUserData,
    isAuthenticated,
    resetAuth,
  } = useAuthContext()
  const location = useLocation()

  const parseAuthToken = () => {
    if (location.hash) {
      webAuth.parseHash({ hash: location.hash }, (err, res) => {
        if (err) {
          // Token Expired or Invalid token
          setAuthError(err)
          resetAuth()
          return
        }
        const { email, name, picture, sub: id } = res!.idTokenPayload
        setUserData({ email, name, picture, id })
        authenticateUser(res?.accessToken!)
        validateToken()
      })
    }
  }

  useEffect(parseAuthToken, [
    authenticateUser,
    location.hash,
    resetAuth,
    setUserData,
  ])

  if (isAuthenticated) {
    return <Navigate to="/partner-portal" />
  }

  if (authError) {
    return <AuthError error={authError} />
  }

  return <Loader />
}
