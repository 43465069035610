import classNames from 'classnames'
import styles from './loader.module.scss'

export const Loader = () => {
  return (
    <div className="w-full h-full relative flex justify-center items-center">
      <div className="z-10 text-white absolute top-1/2 -translate-y-1/2">
        <div className={styles['animation-container']}>
          <div className={styles['lightning-container']}>
            <div className={classNames(styles.lightning, styles.white)} />
            <div className={classNames(styles.lightning, styles.red)} />
          </div>
          <div className={styles['boom-container']}>
            <div
              className={classNames(
                styles.shape,
                styles.circle,
                styles.big,
                styles.white
              )}
            />
            <div
              className={classNames(styles.shape, styles.circle, styles.white)}
            />
            <div
              className={classNames(
                styles.shape,
                styles.triangle,
                styles.big,
                styles.yellow
              )}
            />
            <div
              className={classNames(styles.shape, styles.disc, styles.white)}
            />
            <div
              className={classNames(styles.shape, styles.triangle, styles.blue)}
            />
          </div>
          <div className={classNames(styles['boom-container'], styles.second)}>
            <div
              className={classNames(
                styles.shape,
                styles.circle,
                styles.big,
                styles.white
              )}
            />
            <div
              className={classNames(styles.shape, styles.circle, styles.white)}
            />
            <div
              className={classNames(styles.shape, styles.disc, styles.white)}
            />
            <div
              className={classNames(styles.shape, styles.triangle, styles.blue)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
