import React from 'react'
import { ReactComponent as Logo } from '~/assets/drop.svg'

export const DropHeading = () => {
  return (
    <div className="mb-6 flex items-center gap-4 divide-x">
      <span className="inline-block w-24 text-drop-green-500">
        <Logo />
      </span>
    </div>
  )
}
