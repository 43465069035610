import api from '~/apis/api'

export type SummaryItem = { name: string; url: string; id: string }
export type Campaign = {
  id: string | number
  brand_id: string | number
  name: string
  starts_on: string
  active_until: string
  days_goal: number
  event_tracking_start: string
  gmv_goal: null | number
  budget_goal: number
  created_at: string
  updated_at: string
  type: 'clo' | 'affiliate' | 'non-affiliate'

  /**
   * The field below does not actually exist, but existing code assumes it does. This needs to be cleaned up.
   * Nothing should break either way as this is marked as optional.
   */
  offerState?: 'launched' | 'review' | 'draft' | 'archived'
}

const fetchBrandSummaries = () => {
  return api.get('/campaigns/summary') as Promise<{
    data: { urls: SummaryItem[] }
  }>
}
const fetchCampaigns = () => {
  return api.get('/campaigns') as Promise<{
    data: { campaigns: Campaign[] }
  }>
}
const fetchCampaignById = (campaignId: string) => {
  return api.get(`/campaigns/${campaignId}`) as Promise<{
    data: { campaign: Campaign }
  }>
}

const fetchLookerEmbedUrl = async (id: string) => {
  const response: { data: string | null } = await api.get(
    `/campaigns/${id}/looker`
  )

  return response.data ? decodeURI(response.data) : null
}

export const QUERY_CONFIG = {
  QUERIES: {
    summaries: {
      queryKey: ['summaries'],
      queryFn: fetchBrandSummaries,
    },

    campaigns: {
      queryKey: ['campaigns'],
      queryFn: fetchCampaigns,
    },
    embedUrlForCampaign: (campaignId: string | undefined) => ({
      queryKey: ['embedUrlForCampaign', campaignId],
      queryFn: () => (campaignId ? fetchLookerEmbedUrl(campaignId) : null),
      enabled: !!campaignId,
    }),
    campaignById: (campaignId: string | undefined) => ({
      queryKey: ['campaignById', campaignId],
      queryFn: () => (campaignId ? fetchCampaignById(campaignId) : null),
      enabled: !!campaignId,
    }),
  },
}
