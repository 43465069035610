import { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { CustomForm } from './CustomFields'
import {
  CLOFields,
  integratedFields,
  nonIntegratedFields,
  reduceValidationsToSchema,
} from './utils'
import { SegmentedButtons } from '~/components'
import { OfferTypes } from '~/constants'
import { FormInput } from './types'

const options = Object.values(OfferTypes)

export const OfferCreation = () => {
  const [selectedForm, setSelectedForm] = useState<OfferTypes>(OfferTypes.CLO)
  const offerTypesFields = {
    [OfferTypes.INTEGRATED]: integratedFields,
    [OfferTypes.NON_INTEGRATED]: nonIntegratedFields,
    [OfferTypes.CLO]: CLOFields,
  }

  const formValidations = reduceValidationsToSchema(
    offerTypesFields[selectedForm]
  )
  const validationSchema = yup.object().shape(formValidations)

  const offerCreationForm = useForm<FormInput>({
    resolver: yupResolver(validationSchema),
  })

  const handleFormChange = useCallback(
    (formId: string) => {
      setSelectedForm(formId as OfferTypes)
      offerCreationForm.reset()
    },
    [offerCreationForm]
  )

  //TODO: Remove console log and add business logic and remove any type by the form type
  /* eslint-disable no-console */
  const onSubmit = useCallback((data: FormInput) => console.log(data), [])

  return (
    <div className="bg-gray-200 flex justify-center py-12">
      <div className="w-full max-w-md">
        <SegmentedButtons
          onClick={handleFormChange}
          options={options}
          selectedValue={selectedForm}
        />
        <CustomForm
          fields={offerTypesFields[selectedForm]}
          onSubmit={onSubmit}
          form={offerCreationForm}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 my-4"
        />
      </div>
    </div>
  )
}
