import { Field } from './types'

export const fields: readonly Field[] = [
  {
    name: 'campaignTimeframe',
    title: 'Campaign timeframe',
    type: 'text',
    validations: {
      type: 'string',
      rules: [
        {
          type: 'required',
          defaultMessage: 'Field is required',
        },
      ],
    },
  },
  {
    name: 'campaignBugetUpfront',
    title: 'Campaign budget (upfront)',
    type: 'text',
    validations: {
      type: 'string',
      rules: [
        {
          type: 'required',
          defaultMessage: 'Field is required',
        },
      ],
    },
  },
  {
    name: 'campaignBugetPerformance',
    title: 'Campaign budget (performance)',
    type: 'text',
    validations: {},
  },
  {
    name: 'currency',
    title: 'Currency',
    type: 'select',
    validations: {
      type: 'string',
      rules: [
        {
          type: 'required',
        },
      ],
    },
    options: [
      { value: 'usd', title: 'USD' },
      { value: 'cad', title: 'CAD' },
    ],
  },
  {
    name: 'campaignGoal',
    title: 'Campaign goal',
    type: 'text',
    validations: {
      type: 'string',
      rules: [
        {
          type: 'required',
        },
      ],
    },
  },
  {
    name: 'offerSegmentation',
    title: 'Offer segmentation',
    type: 'select',
    validations: {
      type: 'string',
      rules: [
        {
          type: 'required',
        },
      ],
    },
    options: [
      { value: '1segment', title: '1 segment' },
      { value: '2segments', title: '2 segments (acquisition + retention)' },
      {
        value: '3segments',
        title: '3 segments (acquisition + battleground + retention)',
      },
    ],
  },
  {
    name: 'competitors',
    title: 'Competitors',
    type: 'text',
    validations: {},
  },
  {
    name: 'brandName',
    title: 'Brand name',
    type: 'text',
    validations: {
      type: 'number',
      rules: [
        {
          type: 'required',
        },
        {
          type: 'max',
          value: 25,
          defaultMessage: 'Max 25',
        },
      ],
    },
  },
  {
    name: 'brandLogo',
    title: 'Brand logo',
    type: 'image',
    validations: {},
  },
  {
    name: 'shortBrandDescription',
    title: 'Short brand description',
    type: 'text',
    validations: {},
  },
  {
    name: 'longBrandDescription',
    title: 'Long brand description',
    type: 'textarea',
  },
  {
    name: 'example',
    title: 'Example Segmented',
    type: 'buttonGroup',
    options: [
      { value: 'clo', title: 'CLO' },
      { value: 'integrated', title: 'Integrated' },
      { value: 'non-integrated', title: 'Non Integrated' },
    ],
  },
]

export const CLOFieldNames: readonly string[] = [
  'campaignTimeframe',
  'campaignBugetUpfront',
  'campaignBugetPerformance',
  'currency',
  'campaignGoal',
  'offerSegmentation',
  'competitors',
  'brandName',
  'brandLogo',
  'longBrandDescription',
]

export const integratedFieldNames: readonly string[] = [
  'brandName',
  'brandLogo',
  'shortBrandDescription',
  'longBrandDescription',
  'example',
]

export const nonIntegratedFieldNames: readonly string[] = [
  'brandName',
  'brandLogo',
  'shortBrandDescription',
  'longBrandDescription',
]
