import React, { useContext } from 'react'
import { TabPanelProps } from '../TabPanel'
import { TabsContext } from '../Tabs'

import styles from './tabPanels.module.scss'

type TabPanelsProps = {
  children: React.ReactElement<TabPanelProps>[] | React.ReactElement
}

export const TabPanels = ({ children }: TabPanelsProps) => {
  const { selectedTab, setSelectedTab } = useContext(TabsContext)
  const content = React.Children.map(children, (child, index) => {
    if (index === 0 && setSelectedTab && !selectedTab) {
      setSelectedTab(child.props.value)
    }
    return React.cloneElement(child, {
      ...(selectedTab === child?.props.value ? { active: true } : {}),
    })
  })

  return <div className={styles['tab-panels']}>{content}</div>
}
