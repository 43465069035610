import toast, { Toaster } from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Alert, DropHeading } from '~/features/ui'
import { otpStart, submitOtp } from '~/apis/auth'
import { ReactComponent as EmailSent } from '~/assets/email-sent.svg'
import { useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorMessage } from '@hookform/error-message'
import { RiErrorWarningFill } from 'react-icons/ri'

const schema = yup.object().shape({
  otp: yup.string().required('One Time Passcode is a required field.'),
})

const FieldErrorMessage = ({ message }: { message: string }) => (
  <p
    className="absolute bottom-2 text-sm first-letter:uppercase hidden group-invalid:block group-invalid:text-red-600"
    id="email-error"
  >
    {message}
  </p>
)

export const EmailSentPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ otp: string }>({
    resolver: yupResolver(schema),
    criteriaMode: 'firstError',
  })

  const [emailProvider, setEmailProvider] = useState<string | null>()
  const [params] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const email = params.get('email')
    if (email) {
      setEmailProvider(email.substring(email.lastIndexOf('@') + 1))
    }
  }, [params])

  const onResendClick = useCallback(async () => {
    try {
      const email = params.get('email')
      if (email) {
        await otpStart({ email })
        toast.success(
          <div className="text-gray-600 text-sm">
            <p className="mb-1">An email has been resent.</p>

            <p className="mx-0">Please check your Inbox and/or Spam folders.</p>
          </div>,
          { duration: 4000, className: 'w-100 min-w-100' }
        )
      } else {
        toast.error("We couldn't find your email. Please try again.", {
          duration: 4000,
        })
        navigate('/')
      }
    } catch (auth0Error) {
      toast.error('Something went wrong', { duration: 4000 })
    }
  }, [params, navigate])

  const onSubmit = async ({ otp }: { otp: string }) => {
    try {
      const email = params.get('email')
      if (email) {
        await submitOtp({
          email,
          verificationCode: otp,
        })
      }
    } catch (auth0Error) {
      setSearchParam({
        error: (auth0Error as Error).message ?? 'Something went wrong.',
      })
    }
  }
  const [searchParams, setSearchParam] = useSearchParams()
  const [error] = useState(searchParams.get('error'))

  return (
    <div className="h-screen flex">
      {!!error && (
        <Alert type="error" icon={<RiErrorWarningFill className="text-2xl" />}>
          {error}
        </Alert>
      )}
      <div className="hidden md:bg-drop-green-500 md:flex-1 md:flex md:items-center md:h-screen md:justify-center">
        <div className="relative bg-drop-purple-500 overflow-hidden w-96 h-96 rounded-3xl flex items-center justify-center">
          <EmailSent />
        </div>
      </div>
      <div className="bg-white flex-1 flex justify-center items-center">
        <div className="max-w-md px-12">
          <DropHeading />
          <h1 className="text-4xl font-bold leading-11 text-gray-800">
            A code has been sent to your email!
          </h1>
          <p className="my-6 w-sm text-gray-600">
            Please check your email inbox and enter the One Time Passcode sent
            to you.
          </p>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col"
            noValidate
          >
            <div className="group form-group" aria-invalid={!!errors.otp}>
              <label
                htmlFor="otp"
                className="block text-sm font-semibold text-gray-700 group-invalid:text-red-600"
              >
                One Time Passcode
                <div className="mt-1 relative rounded-md">
                  <input
                    type="text"
                    id="otp"
                    className="form-control"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('otp')}
                  />
                </div>
              </label>

              <ErrorMessage
                errors={errors}
                name="otp"
                render={FieldErrorMessage}
              />
            </div>
            <div>
              <button type="submit" className="btn btn-primary">
                Continue
              </button>
            </div>
          </form>

          {emailProvider === 'gmail' && (
            <a
              href="https://gmail.com"
              className="inline-block btn btn-primary"
            >
              Open Gmail
            </a>
          )}
          <p className="mt-6 text-gray-600">
            Don't see an email in your inbox?
            <br />
            <button
              className="underline text-drop-purple-500"
              type="button"
              onClick={onResendClick}
            >
              Resend email
            </button>{' '}
            or{' '}
            <a href="mailto:clientsuccess@joindrop.com" rel="noreferrer">
              contact Client Success
            </a>
          </p>
        </div>
      </div>
      <Toaster />
    </div>
  )
}
