import { useParams } from 'react-router-dom'
import styles from './viewPerformanceSummary.module.scss'
import { useSummaryDataForBrand } from '~/hooks/useSummaryDataForBrand'

const ViewPerformanceSummary = () => {
  const { brandId } = useParams()

  const { data } = useSummaryDataForBrand(brandId)

  return (
    <div>
      <div
        className="px-4 py-8"
        style={{
          backgroundColor: 'white',
          border: '1px solid #EAEAEB',
        }}
      >
        <section id="heading" className="container mx-auto px-4">
          <h1>{data?.name}</h1>
        </section>
      </div>

      <div className="container mx-auto px-4">
        <section
          id="performance-banner"
          className="relative rounded-lg bg-drop-green-500 p-6 my-4 mt-12 mx-auto text-white"
        >
          <div className="flex flex-col lg:flex-row items-center justify-between gap-4">
            <h2 className="heading-2 text-white">
              Need assistance with your campaign?
            </h2>
            <div className="flex gap-3">
              <a
                href="mailto:clientsuccess@joindrop.com"
                className="btn-black w-48"
              >
                Contact Us
              </a>
            </div>
          </div>
        </section>
        {!!data && (
          <iframe
            className={styles['looker-dashboard']}
            title="Looker dashboard"
            src={data.url}
          />
        )}
      </div>
    </div>
  )
}

export default ViewPerformanceSummary
