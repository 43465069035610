import React from 'react'
import cn from 'classnames'

import styles from './steps.module.scss'

type StepsProps = {
  className?: string
  children: React.ReactNode
}

export const Steps = ({ children, className = '' }: StepsProps) => {
  const stepsClasses = cn(styles.steps, className)

  return <div className={stepsClasses}>{children}</div>
}
