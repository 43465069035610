import { Auth0ParseHashError } from 'auth0-js'
import React from 'react'
import { ReactComponent as AuthError403 } from '~/assets/403-Error.svg'
import { DropHeading } from '../ui'

interface AuthErrorProps {
  error: Auth0ParseHashError
}

const ERROR_MAPS: Record<string, string> = {
  unauthorized: 'Oops! Looks like something went wrong.',
  'You’ve reach the maximum number of login attempts. Please try to log in again.':
    'You’ve reach the max login attempts.',
  'Wrong email or verification code.': 'This one-time link has expired.',
}

export const AuthError = ({ error }: AuthErrorProps) => {
  if (error) {
    return (
      <div className="h-screen flex">
        <div className="hidden md:bg-drop-green-500 md:flex-1 md:flex md:items-center md:h-screen md:justify-center">
          <div className="relative bg-drop-purple-500 overflow-hidden w-96 h-96 rounded-3xl flex items-center justify-center">
            <AuthError403 />
          </div>
        </div>
        <div className="bg-white flex-1 flex justify-center items-center">
          <div className="max-w-xl px-12">
            <DropHeading />
            <h1 className="text-2xl font-bold leading-1 text-red-800">
              {ERROR_MAPS[error.error] ?? error.error}
            </h1>
            <p className="my-6 w-sm text-gray-600">
              {ERROR_MAPS[error.errorDescription as string] ??
                error.errorDescription}
            </p>
            <p className="mt-8 text-gray-600">
              <a href="/" className="btn btn-primary px-12 py-4">
                Try Again
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }

  return null
}
