import { useCallback, useState } from 'react'
import { BsArrowRepeat } from 'react-icons/bs'
import { FaCheck } from 'react-icons/fa'
import styles from './saveButton.module.scss'

type Status = 'un-saved' | 'saving' | 'saved'

const statusInfo = {
  'un-saved': {
    classes: styles['un-save'],
    content: 'Save',
  },
  saving: {
    classes: styles.saving,
    content: (
      <>
        <span className={styles['saving-icon']}>
          <BsArrowRepeat />
        </span>
        <span>Saving</span>
      </>
    ),
  },
  saved: {
    content: (
      <>
        <span className={styles['saved-icon']}>
          <FaCheck />
        </span>
        <span>Saved</span>
      </>
    ),
    classes: styles.saved,
  },
}

export const SaveButton = () => {
  const [status, setStatus] = useState<Status>('un-saved')

  const handleClick = useCallback(() => {
    setStatus('saving')
    setTimeout(() => setStatus('saved'), 1000)
  }, [])

  return (
    <button
      type="button"
      onClick={handleClick}
      className={statusInfo[status].classes}
    >
      {statusInfo[status].content}
    </button>
  )
}
