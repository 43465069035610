import styles from './label.module.scss'

type Props = { htmlFor: string; label: string }

export const Label = ({ htmlFor, label }: Props) => {
  return (
    <label htmlFor={htmlFor} className={styles.label}>
      {label}
    </label>
  )
}
