import { call, put } from 'redux-saga/effects'
import { createOffersAsync } from './actions'
import { Offer, OffersActionTypes } from './types'
import { createOffer } from '~/apis/offers'

export function* createOffers({
  payload,
}: {
  payload: { offer: Offer; onSuccess(): void }
  type: OffersActionTypes.CREATE_OFFER_REQUEST
}) {
  try {
    const response: {
      data: Offer
    } = yield call(() => createOffer(payload.offer))
    yield put(createOffersAsync.success(response.data))
    yield call(payload.onSuccess)
  } catch (error) {
    yield put(createOffersAsync.failure(error as Error))
  }
}
