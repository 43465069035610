export type TabPanelProps = {
  value: number | string | null
  children?: React.ReactElement
  active?: boolean
}

export const TabPanel = ({ children, active, value }: TabPanelProps) => {
  if (!active || !value) return null
  return <div>{children}</div>
}
