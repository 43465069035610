import { FC, useCallback, useContext } from 'react'
import cn from 'classnames'

import styles from './tab.module.scss'
import { TabsContext } from '../Tabs'

export type TabProps = {
  value: number | string
  className?: string
  onSelect?: (arg0: string | number) => void
}

export const Tab: FC<TabProps> = ({ children, value, className, onSelect }) => {
  const { setSelectedTab, selectedTab } = useContext(TabsContext)

  const tabClasses = cn(
    { [styles['tab-active']]: selectedTab === value },
    styles.tab,
    className
  )
  const handleTabClick = useCallback(() => {
    if (setSelectedTab) setSelectedTab(value)
    if (onSelect) onSelect(value)
  }, [value, onSelect, setSelectedTab])

  return (
    <div
      className={tabClasses}
      tabIndex={0}
      role="button"
      onClick={handleTabClick}
      onKeyDown={handleTabClick}
    >
      {children}
    </div>
  )
}
