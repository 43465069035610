import { useQuery } from '@tanstack/react-query'
import { QUERY_CONFIG } from '~/apis/queries'

export function useSummaryDataForBrand(brandId: string | undefined) {
  const summariesQuery = useQuery(QUERY_CONFIG.QUERIES.summaries)

  const data = summariesQuery.data?.data.urls.find(
    x => String(x.id) === String(brandId)
  )

  const { isLoading, error } = summariesQuery

  return { data, isLoading, error }
}
