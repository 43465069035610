import { useState, useCallback, useMemo } from 'react'
import { TextInput, Card, SingleSelect } from '~/components/index'
import { useAppDispatch } from '~/store/hooks'
import { createOffersAsync } from '~/store/ducks/offers/actions'
import { Button } from '~/components/Button'
import styles from './createOffer.module.scss'
import { useNavigate } from 'react-router-dom'
import { OfferTypes, OfferStates } from '~/constants'

const offerTypes = [
  {
    type: 'Card-Linked Offer',
    description:
      'Customers link their bank account to the drop application. Drop can then detect your transactions are reward you for purchases made at the participating Merchants.',
  },
  {
    type: 'Affiliate Offer',
    description:
      'Where when customer clicks on an offer they are taken to the store website. If the customer completes a purchase on this URL. They are rewarded based on the offer. ',
    isDisabled: true,
  },
  {
    type: 'Direct Offer',
    description:
      'About direct offer goes here. Dop can about direct offer goes here. Customers link their bank account to the drop application.',
    isDisabled: true,
  },
]

export const CreateOffer = () => {
  const [internalName, setInternalName] = useState('')
  const [internalState, setInternalState] = useState(OfferStates.LAUNCHED)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleStateChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>): void => {
      setInternalState(e.target.value as OfferStates)
    },
    [setInternalState]
  )

  const handleSubmit = useCallback(() => {
    dispatch(
      createOffersAsync.request({
        offer: {
          name: internalName,
          type: OfferTypes.CLO,
          offerState: internalState,
        },
        onSuccess: () => navigate('/partner-portal/affiliate-offer'),
      })
    )
  }, [dispatch, internalName, internalState, navigate])

  const handleTextInputChange = useCallback(
    ({ target }) => setInternalName(target.value),
    [setInternalName]
  )

  const OPTIONS = useMemo(
    () => [
      { value: OfferStates.LAUNCHED, title: OfferStates.LAUNCHED },
      { value: OfferStates.REVIEW, title: OfferStates.REVIEW },
      { value: OfferStates.DRAFT, title: OfferStates.DRAFT },
    ],
    []
  )

  return (
    <div className={styles['create-container']}>
      <div className={styles.header}>
        <h1 className={styles.title}>Create a new offer</h1>
        <h2 className={styles.subtitle}>Internal Offer Name</h2>
        <p className={styles.text}>This is visible only to your brand.</p>
        <TextInput
          label="Internal offer name"
          value={internalName}
          onChange={handleTextInputChange}
          placeholder="Offer 01"
        />
        <p className={styles.text}>Select State(Demo)</p>
        <SingleSelect
          onSelect={handleStateChange}
          selectedValue={internalState}
          label="Select State(Demo)"
          options={OPTIONS}
        />
        <h2 className={styles['type-title']}>Offer type</h2>
      </div>
      <div className={styles['types-container']}>
        {offerTypes.map(({ type, description, isDisabled }) => (
          <Card isDisabled={isDisabled}>
            <Card.Image
              title={type}
              image="https://kibion.se/content/uploads/2013/06/staff-portrait-placeholder-gray.jpg"
            />
            <Card.Content text={description}>
              <Button onClick={handleSubmit}>{type}</Button>
            </Card.Content>
          </Card>
        ))}
      </div>
    </div>
  )
}
