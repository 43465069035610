import React, { ComponentType, FC } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthContext } from '~/features/auth/auth-context'

interface Props {
  component: ComponentType
  redirectTo?: string
}

export const PrivateRoute: FC<Props> = ({
  component: RouteComponent,
  redirectTo,
}) => {
  const { isAuthenticated } = useAuthContext()

  if (isAuthenticated) {
    return <RouteComponent />
  }

  return <Navigate to={redirectTo!} />
}

PrivateRoute.defaultProps = {
  redirectTo: '/',
}
