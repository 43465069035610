import styles from './emptyList.module.scss'

export const EmptyList = () => (
  <div className={styles.container}>
    <p className={styles['section-title']}>
      Looks like your offer isn’t live yet
    </p>
    <a
      href="mailto:clientsuccess@joindrop.com"
      className={styles['complete-offer']}
    >
      Contact us
    </a>
  </div>
)
