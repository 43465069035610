import { useContext } from 'react'
import { StepperContext } from '~/components'

export const useStepper = () => {
  const { selectedStep, setSelectedStep, steps = [] } = useContext(
    StepperContext
  )

  if (!StepperContext) {
    throw new Error('useStepper should be used inside StepperProvider')
  }

  const index = selectedStep ? steps.indexOf(selectedStep) : 0
  const isLastStep = index >= steps.length - 1
  const isFirstStep = index === 0

  const goBack = () => {
    if (!selectedStep || !setSelectedStep) return
    if (index === 0) return
    setSelectedStep(steps[index - 1])
  }

  const goNext = () => {
    if (!selectedStep || !setSelectedStep) return
    if (isLastStep) return
    setSelectedStep(steps[index + 1])
  }

  return {
    goBack,
    goNext,
    selectedStep,
    isLastStep,
    isFirstStep,
  }
}
