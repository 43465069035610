import { useContext } from 'react'
import { StepperContext } from '../Stepper'

type StepContentProps = {
  className?: string
  name: string | number | null
  children: React.ReactNode
}

export const StepContent = ({
  children,
  className = '',
  name,
}: StepContentProps) => {
  const { selectedStep } = useContext(StepperContext)

  if (selectedStep !== name) return null

  return (
    <div data-target={name} className={className}>
      {children}
    </div>
  )
}
