/* eslint-disable jsx-a11y/label-has-associated-control */
import * as yup from 'yup'
import { useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { RiErrorWarningFill } from 'react-icons/ri'

import { Alert } from '../ui/alert/alert'
import { otpStart } from '~/apis/auth'
import { ErrorMessage } from '@hookform/error-message'

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address.')
    .required('Email is a required field.'),
})

const FieldErrorMessage = ({ message }: { message: string }) => (
  <p
    className="absolute bottom-2 text-sm first-letter:uppercase hidden group-invalid:block group-invalid:text-red-600"
    id="email-error"
  >
    {message}
  </p>
)

export const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({
    resolver: yupResolver(schema),
    criteriaMode: 'firstError',
  })
  const [searchParams, setSearchParam] = useSearchParams()
  const [error] = useState(searchParams.get('error'))
  const navigate = useNavigate()

  const onSubmit = async ({ email }: { email: string }) => {
    const confirmationPath = `/email-sent${email ? `?email=${email}` : ''}`
    try {
      await otpStart({ email })
      navigate(confirmationPath)
    } catch (auth0Error) {
      setSearchParam({
        error: (auth0Error as Error).message ?? 'Something went wrong.',
      })
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col"
      noValidate
    >
      {!!error && (
        <Alert type="error" icon={<RiErrorWarningFill className="text-2xl" />}>
          {error}
        </Alert>
      )}
      <div className="group form-group" aria-invalid={!!errors.email}>
        <label
          htmlFor="email"
          className="block text-sm font-semibold text-gray-700 group-invalid:text-red-600"
        >
          Email
        </label>
        <div className="mt-1 relative rounded-md">
          <input
            type="email"
            id="email"
            className="form-control"
            placeholder="mail@yourbrand.com"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('email')}
          />
        </div>
        <ErrorMessage errors={errors} name="email" render={FieldErrorMessage} />
      </div>
      <div>
        <button type="submit" className="btn btn-primary">
          Send Code
        </button>
      </div>
    </form>
  )
}
