import { Outlet } from 'react-router-dom'

import { Navbar } from '../Navbar'

export const PageLayout = () => (
  <div>
    <Navbar />
    <div className="content pt-24">
      <Outlet />
    </div>
  </div>
)
