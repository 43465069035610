import styles from './button.module.scss'

type Props = {
  children: React.ReactNode
  isDisabled?: boolean
  onClick(): void
  className?: string
}

export const Button = ({ children, isDisabled, onClick, className }: Props) => (
  <button
    type="button"
    className={`${styles.button} ${className}`}
    disabled={isDisabled}
    onClick={onClick}
  >
    <span>{children}</span>
  </button>
)

Button.defaultProps = {
  isDisabled: false,
  className: '',
}
