import React, { Dispatch, SetStateAction } from 'react'

import styles from './stepper.module.scss'

type Step = number | string | null

type StepperContextType = {
  selectedStep?: Step
  setSelectedStep?: Dispatch<SetStateAction<Step>>
  steps?: Step[]
  setSteps?: Dispatch<SetStateAction<Step[]>>
}

type StepperProps = {
  className?: string
  children: React.ReactNode
}

type StepperProviderProps = {
  children: React.ReactNode
}

export const StepperContext = React.createContext<StepperContextType>({})

const StepperProvider = ({ children }: StepperProviderProps) => {
  const [selectedStep, setSelectedStep] = React.useState<Step>(null)
  const [steps, setSteps] = React.useState<Step[]>([])
  const value = React.useMemo(
    () => ({ selectedStep, setSelectedStep, setSteps, steps }),
    [selectedStep, steps]
  )

  return (
    <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  )
}

export const Stepper = ({ children, className }: StepperProps) => {
  return (
    <StepperProvider>
      <div className={`${styles.stepper} ${className}`}>{children}</div>
    </StepperProvider>
  )
}
