import { Provider } from 'react-redux'
import store from '~/store'

interface ReduxContainerProps {
  children: React.ReactNode
}

export const ReduxContainer = ({ children }: ReduxContainerProps) => {
  return <Provider store={store}>{children}</Provider>
}
