import {
  createContext,
  FC,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react'

import styles from './tabs.module.scss'

type TabsProps = {
  initTab?: number | string
}

type TabsContextType = {
  selectedTab?: number | string | null
  setSelectedTab?: Dispatch<SetStateAction<number | string | null>>
  initTab?: number | string
}

export const TabsContext = createContext<TabsContextType>({})

export const Tabs: FC<TabsProps> = ({ children, initTab, ...restProps }) => {
  const [selectedTab, setSelectedTab] = useState<number | string | null>(
    initTab || null
  )
  const value: TabsContextType = useMemo(
    () => ({ selectedTab, setSelectedTab, initTab }),
    [initTab, selectedTab]
  )
  return (
    <TabsContext.Provider value={value}>
      <div className={styles.tabs} {...restProps}>
        {children}
      </div>
    </TabsContext.Provider>
  )
}
