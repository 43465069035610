export type Offer = {
  id?: string
  name: string
  type: OfferTypes
  offerState: OfferStates
  created_at: number
  events_tracked_started_at: number
}

export enum OfferTypes {
  CLO = 'clo',
  INTEGRATED = 'affiliate',
  NON_INTEGRATED = 'non-affiliate',
}

export enum OfferStates {
  LAUNCHED = 'launched',
  REVIEW = 'review',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
}
