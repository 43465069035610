import styles from './card.module.scss'

type Props = {
  text: string
  children: React.ReactNode
}

export const Content = ({ text, children }: Props) => {
  return (
    <div className={styles['card-content']}>
      <div>
        <p className={styles['card-description']}>{text}</p>
      </div>
      {children}
    </div>
  )
}
