import auth0, { PasswordlessStartOptions } from 'auth0-js'
import storage from 'store2'
import { AUTH_TOKEN } from '~/features/auth/auth-context'
import api from './api'

const SCOPE = 'openid email profile'

export const webAuth = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN!,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
  responseType: 'token id_token',
  redirectUri: `${window.location.origin}/authorize`,
  scope: SCOPE,
})

export const otpStart = ({ email }: { email: string }) => {
  return new Promise((resolve, reject) => {
    const variables: PasswordlessStartOptions = {
      email,
      connection: 'email',
      send: 'code',
    }
    webAuth.passwordlessStart(variables, (err, res) => {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  })
}

export const submitOtp = ({
  email,
  verificationCode,
}: {
  email: string
  verificationCode: string
}) => {
  return new Promise((resolve, reject) => {
    webAuth.passwordlessLogin(
      {
        connection: 'email',
        email,
        verificationCode,
      },
      (err, res) => {
        if (err) reject(err)
        else resolve(res)
      }
    )
  })
}

export const logout = () => {
  storage.remove(AUTH_TOKEN)
  webAuth.logout({
    returnTo: window.location.origin,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  })
}

export const checkSession = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    webAuth.checkSession(
      {
        scope: SCOPE,
      },
      (err, authResult) => {
        if (err) {
          reject(err)
        } else {
          resolve(authResult)
        }
      }
    )
  })
}

// Validation on API Level
export const validateToken = async () => {
  await api.post('/sessions/validate', {})
}
