import { useCallback, useRef, useState } from 'react'
import { BsThreeDots } from 'react-icons/bs'
import cn from 'classnames'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import styles from './offerListCard.module.scss'
import { ReactComponent as CardIcon } from '~/assets/card.svg'
import { ReactComponent as LinkIcon } from '~/assets/affiliate.svg'
import { OfferTypes, OfferStates } from '~/constants'
import { MenuDropDown } from '~/features/ui'
import { useFeature } from '@growthbook/growthbook-react'
import { Campaign } from '~/apis/queries'

type PerformanceListCardProps = {
  campaign: Campaign
}

const offerText = {
  [OfferStates.LAUNCHED]: 'Weekly performance reports are emailed to you',
  [OfferStates.REVIEW]: 'Your offer is currently in review',
  [OfferStates.DRAFT]: 'Get this offer out by filling in the details',
  [OfferStates.ARCHIVED]: '',
}

const offerIcon = {
  [OfferTypes.CLO]: <CardIcon />,
  [OfferTypes.INTEGRATED]: <LinkIcon />,
  [OfferTypes.NON_INTEGRATED]: <LinkIcon />,
}

const offerDateText = (dateValue: string) => ({
  [OfferStates.LAUNCHED]: `Launched ${dateValue}`,
  [OfferStates.REVIEW]: `Submitted ${dateValue}`,
  [OfferStates.DRAFT]: `Last Updated ${dateValue}`,
  [OfferStates.ARCHIVED]: '',
})

export const PerformanceListCard = ({ campaign }: PerformanceListCardProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const hasOfferCardActionsEnabled = useFeature('offer-card-actions-enabled').on
  const isReview = campaign.offerState === OfferStates.REVIEW
  const isDraft = campaign.offerState === OfferStates.DRAFT
  const contClasses = cn(
    {
      [styles['review-box']]: isReview,
      [styles['draft-box']]: campaign.offerState === OfferStates.DRAFT,
    },
    styles['card-list']
  )

  const stateClasses = cn(
    {
      [styles['review-state']]: isReview,
      [styles['draft-state']]: isDraft,
    },
    styles['offer-state-box']
  )

  const circleClasses = cn(
    {
      [styles['review-circle']]: isReview,
      [styles['draft-circle']]: isDraft,
    },
    styles['state-circle']
  )

  const icon = offerIcon[campaign.type] || offerIcon[OfferTypes.CLO]
  const text =
    (campaign.offerState && offerText[campaign.offerState]) ||
    offerText[OfferStates.LAUNCHED]

  const formattedOfferDate = useCallback(() => {
    if (!campaign.event_tracking_start) return null
    const offerDate = new Date(campaign.event_tracking_start)
    const offerDateString = format(offerDate, 'MMM dd, yyyy')
    return offerDateText(offerDateString)[
      campaign.offerState || OfferStates.LAUNCHED
    ]
  }, [campaign])

  const showAction = () => {
    if (!campaign.offerState || campaign.offerState === OfferStates.LAUNCHED) {
      return (
        <Link
          to={`performance/${campaign.id}`}
          className={styles['performance-btn']}
        >
          View Performance
        </Link>
      )
    }
    return (
      <Link
        to={`performance/${campaign.id}`}
        className={styles['performance-btn']}
      >
        Show Offer
      </Link>
    )
  }

  const handleOutSideMenuClick = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  const handleMenuClick = useCallback(() => {
    setIsMenuOpen(!isMenuOpen)
  }, [isMenuOpen])

  return (
    <div className={contClasses}>
      <div className={styles['top-section']}>
        <h2 className={styles['offer-name']}>{campaign.name}</h2>
        <div className={styles['performance-box']}>
          {showAction()}
          {hasOfferCardActionsEnabled && (
            <div
              className={styles['options-btn']}
              onClick={handleMenuClick}
              onKeyDown={handleMenuClick}
              tabIndex={0}
              role="button"
              ref={menuRef}
            >
              <BsThreeDots className={styles['menu-icon']} />
              <MenuDropDown
                onClickOutside={handleOutSideMenuClick}
                ignoreElement={menuRef}
                isOpen={isMenuOpen}
              >
                <button type="button" className={styles['drop-down-item']}>
                  View Performance
                </button>
                <button type="button" className={styles['drop-down-item']}>
                  Download Performance Report
                </button>
                <button type="button" className={styles['drop-down-item']}>
                  Download Transactions Report
                </button>
                <button type="button" className={styles['drop-down-item']}>
                  View Details
                </button>
              </MenuDropDown>
            </div>
          )}
        </div>
      </div>
      <div className={styles['bottom-section']}>
        <div className={styles['b-left-box']}>
          <div className={stateClasses}>
            <div className={circleClasses} />
            <p className={styles['offer-state']}>
              {campaign.offerState || OfferStates.LAUNCHED}
            </p>
          </div>
          <p className={styles['offer-type']}>{icon} Card-Linked Offer</p>
        </div>
        <div className={styles['b-right-box']}>
          <p className={styles['offer-message']}>{text}</p>
          <p className={styles['offer-date']}>{formattedOfferDate()}</p>
        </div>
      </div>
    </div>
  )
}
