import { Link } from 'react-router-dom'
import {
  FormHeader,
  Stepper,
  Steps,
  Step,
  StepContentPanel,
  StepContent,
} from '~/components'
import { HiArrowLeft } from 'react-icons/hi'
import { FaCheck } from 'react-icons/fa'
import { BsQuestionLg } from 'react-icons/bs'

import styles from './affiliateOfferCreation.module.scss'
import { StepNav } from './StepNav'

export const AffiliateOfferCreation = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles['left-side']}>
          <Link to="/" className={styles['back-save-link']}>
            <div className={styles['back-arrow']}>
              <HiArrowLeft />
            </div>
            <p className="w-32">Save and return to Dashboard</p>
          </Link>
        </div>
        <FormHeader
          className={styles['right-side']}
          title="Tell us about your brand"
        />
      </div>
      <Stepper className={styles.stepper}>
        <div className={styles['fixed-sidebar']}>
          <Steps className={styles.steps}>
            <div className={styles['offer-container']}>
              <h4 className={styles['offer-title']}>Acquisition - ID 2706</h4>
              <p className={styles['offer-type']}>Card-Linked Offer</p>
            </div>
            <Step title="brand" htmlFor="brand" icon={1} />
            <Step title="Campaign" htmlFor="campaign" icon={2} />
            <Step title="Reporting" htmlFor="reporting" icon={3} />
            <Step
              title="Submit for review"
              htmlFor="submit"
              icon={<FaCheck />}
            />
          </Steps>
          <div className={styles['support-container']}>
            <Link to="/" className={styles['support-link']}>
              <div className={styles['support-icon']}>
                <BsQuestionLg />
              </div>
              Get support
            </Link>
          </div>
        </div>
        <StepContentPanel className={styles['step-content-panel']}>
          <StepContent name="brand">
            {/* TODO: Remove this with real data, this is just a scrolling example */}
            <h1 className="text-center text-xl font-bold">BRAND</h1>
            <p className="text-center">
              This is a brief description of your brand
            </p>
            <div className="h-52 bg-blue-200 w-full mt-4" />
            <div className="h-52 bg-pink-200 w-full mt-4" />
            <div className="h-52 bg-green-200 w-full mt-4" />
            <div className="h-52 bg-yellow-200 w-full mt-4" />
          </StepContent>
          <StepContent name="campaign">2 - Campaign</StepContent>
          <StepContent name="reporting">3 - Reporting</StepContent>
          <StepContent name="submit">4 - Submit for review</StepContent>
          <StepNav />
        </StepContentPanel>
      </Stepper>
    </>
  )
}
